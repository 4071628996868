import React, { createContext, useState } from 'react';
export const PofContext = createContext(null);

const PofContextProvider = ({ children }) => {
    const [baseUrl, setBase] = useState(null);


    return (
        <PofContext.Provider
            value={{
                baseUrl, setBase,
            }}
        >
            {children}
        </PofContext.Provider>
    )

};

export default PofContextProvider;
