import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import NavBar from './Components/Navbar/navbar.component';
import PofContextProvider from "./context/pof.context";
const StatusCheck = React.lazy(() => import('./Components/Landing/landing.component'));


class App extends Component {
  render() {
    return (
        <PofContextProvider>
            <Router>
                <div className="App">
                    <NavBar />
                    <React.Suspense fallback=''>
                        <Route path='/' component={StatusCheck}><StatusCheck/></Route>
                    </React.Suspense>
                </div>
            </Router>
        </PofContextProvider>
    )
  }
}

export default App;
