import React from 'react';
import './navbar.styles.scss';

const NavBar = () => {
    return (
        <div className='header container-fluid'>
            <nav>
                <div className='nav-brand'>
                    <a className='navbar-brand' href='/'><div className='nav-logo'/></a>
                </div>

            </nav>
        </div>
    )
};

export default NavBar;
